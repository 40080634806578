import type { Permissions } from '@mntn-dev/auth-types'

import type { FileArea } from '@mntn-dev/domain-types'
import type { PermissionContext } from './types.ts'

export const createPermissions = ({
  userType,
}: PermissionContext): Permissions => {
  const isSystemUser = userType === 'system'
  const isInternalUser = userType === 'internal'

  const isAdminUser = isInternalUser || isSystemUser

  const isBrandUser = userType === 'brand'
  const isMakerUser = userType === 'maker'

  const isAnyUser = isSystemUser || isInternalUser || isBrandUser || isMakerUser

  return {
    canAcceptOffer: () => isMakerUser,
    canAdministerPackages: () => isAdminUser,
    canAdministerProjects: () => isAdminUser,
    canAdministerUsers: () => isAdminUser,
    canAdministerWatches: () => isAdminUser,
    canArchiveProject: () => isBrandUser,
    canCommentOnProject: () => isBrandUser || isMakerUser,
    canCompleteProject: () => isMakerUser || isAdminUser,
    canCreateActivity: () => isAnyUser,
    canCreateProject: () => isBrandUser || isSystemUser,
    canCreateReview: () => isSystemUser,
    canCreateWatch: () => isSystemUser,
    canDeclineOffer: () => isMakerUser,
    canDeleteProject: () => isBrandUser,
    canDeleteWatch: () => isSystemUser,
    canEditMatch: () => isInternalUser,
    canEditProject: () => isBrandUser || isAdminUser,
    canRemoveMatch: () => isInternalUser,
    canSubmitProject: () => isBrandUser || isSystemUser,
    canSubscribeToInternalUpdates: () => isAdminUser,
    canUpdateReviewFeedback: () => isBrandUser,
    canUpdateReviewProposal: () => isMakerUser,
    canUpdateWatch: () => isSystemUser,
    canUploadFile: (_fileType: FileArea) => isAnyUser, //TODO: actually check if the user can upload the file
    canViewActivity: () => isAnyUser,
    canViewAgency: () => isMakerUser || isAdminUser,
    canViewAllWatches: () => isSystemUser,
    canViewBrand: () => isBrandUser || isAdminUser,
    canViewCost: () => isMakerUser || isAdminUser,
    canViewCostPlusMargin: () => isBrandUser || isAdminUser,
    canViewInternalPricingNote: () => isBrandUser || isAdminUser,
    canViewMatch: () => isInternalUser,
    canViewOffer: () => isMakerUser || isAdminUser,
    canViewPackages: () => isBrandUser || isAdminUser,
    canViewProject: () => isAnyUser,
    canViewWatch: () => isSystemUser,
  }
}
